<div class="flex flex-col gap-4">
  <div>
    <h1 data-test-id="user-list-title">User management</h1>
    <hr>
  </div>
  <div *ngIf="clients">
    <div class="flex flex-row">
      <div class="flex-1">
        <app-users-filter [clients]="clients" (onChange)="onFilterChange($event)"></app-users-filter>
      </div>
      <div>
        <button type="button" mat-flat-button color="primary" [routerLink]="'invite'" data-test-id="user-list-create">
          <mat-icon>person_add</mat-icon> <span data-test-id="user-list-create-label">Invite user</span>
        </button>
      </div>
    </div>

    <div>
      <mat-table matSort [dataSource]="dataSource">
        <ng-contianer matColumnDef="userType">
          <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
          <mat-cell *matCellDef="let element" data-test-id="user-list-table-user-type"> {{element.userType}} </mat-cell>
        </ng-contianer>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
          <mat-cell *matCellDef="let element" data-test-id="user-list-table-name">
            {{element.firstName}} {{element.lastName}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
          <mat-cell *matCellDef="let element" data-test-id="user-list-table-email"> {{element.email}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="client">
          <mat-header-cell *matHeaderCellDef> Clients </mat-header-cell>
          <mat-cell *matCellDef="let element" data-test-id="user-list-table-clients">
            {{element.clients}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu" data-test-id="user-list-table-actions-button">
              <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item [routerLink]="['edit/', element.connectId]"
                data-test-id="user-list-table-actions-edit">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>
              <button mat-menu-item disabled data-test-id="user-list-table-actions-delete">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
      </mat-table>
      <mat-paginator [pageSize]="10" showFirstLastButtons></mat-paginator>
    </div>
  </div>
  <app-loading-spinner *ngIf="!clients"></app-loading-spinner>
</div>