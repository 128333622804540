import { Component } from '@angular/core';
import { CognitoAuthService } from '@techspert-io/auth';
import { ISideBarRoute } from './nav-menu-link/nav-menu-link.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  sidebarToggle = false;
  routes: ISideBarRoute[] = [
    {
      label: 'Dashboard',
      icon: 'dashboard',
      route: '/admin/dashboard',
    },
    {
      label: 'Clients',
      icon: 'people_outline',
      route: '/admin/clients',
    },
    {
      label: 'Calls',
      icon: 'call',
      route: '/admin/conference-management',
    },
    {
      label: 'Search',
      icon: 'search',
      route: '/admin/search',
    },
    {
      label: 'Manual upload',
      icon: 'manage_search',
      route: '/admin/manual-upload',
    },
    {
      label: 'Templates',
      icon: 'drafts',
      route: '/admin/outreach-templates',
    },
    {
      label: 'Users',
      icon: 'assignment_ind',
      route: '/admin/users',
    },
    {
      label: 'Project payments',
      icon: 'payment',
      route: '/admin/project-payments',
    },
    {
      label: 'Payments',
      icon: 'payments',
      route: '/admin/payments',
    },
    {
      label: 'Feasibility',
      icon: 'bar_chart',
      route: '/admin/feasibility',
    },
    {
      label: 'Blocked experts',
      icon: 'mail_lock',
      route: '/admin/expert-outreach-status',
    },
  ];

  get showSanta(): boolean {
    return new Date().getMonth() === 11;
  }

  constructor(private cognitoAuthService: CognitoAuthService) {}

  toggleSidebar(): void {
    this.sidebarToggle = !this.sidebarToggle;
  }

  logout(): void {
    this.cognitoAuthService.logOut().subscribe();
  }
}
