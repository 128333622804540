import {
  Component,
  Input,
  Optional,
  Self,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';

type cbChange = (c: string[]) => void;
type cbTouch = () => void;

@Component({
  selector: 'ct-chip-list-control',
  encapsulation: ViewEncapsulation.None,
  template: `
    <mat-form-field class="w-full">
      <mat-label>{{ label }}</mat-label>
      <mat-chip-grid #chipGrid>
        <mat-chip-row *ngFor="let item of list" (removed)="removeChip(item)">
          {{ item }}
          <button matChipRemove (click)="removeChip(item)">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      </mat-chip-grid>
      <input
        matInput
        [placeholder]="placeholder"
        [matChipInputFor]="chipGrid"
        (matChipInputTokenEnd)="addChip($event)"
      />
      <mat-hint *ngIf="hint">
        {{ hint }}
      </mat-hint>
    </mat-form-field>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class ChipListControlComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() hint?: string;
  @Input() uniqueValues = false;

  touched = false;
  disabled = false;
  list: string[] = [];
  set value(val: string[]) {
    this.list = val;
  }

  onChange: cbChange = () => void 0;
  onTouch: cbTouch = () => void 0;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  writeValue(value: string[]): void {
    this.value = value;
  }

  registerOnChange(fn: cbChange): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: cbTouch): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  addChip(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value && (!this.uniqueValues || !this.list.includes(value))) {
      this.list = [...this.list, value].sort();
      this.change();
    }
    event.chipInput.clear();
  }

  removeChip(item: string): void {
    this.list = this.list.filter((l) => l !== item);
    this.change();
  }

  private change(): void {
    if (!this.touched) {
      this.onTouch();
      this.touched = true;
    }
    this.onChange(this.list);
  }
}
