import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { ConnectUser, IUserBase } from '@techspert-io/auth';
import {
  ClientActions,
  IClient,
  selectAllClients,
} from '@techspert-io/clients';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { IUsersFilterEvent } from '../users-filter/users-filter.component';

export interface IUser extends IUserBase {
  clients?: string[];
}

@Component({
  selector: 'app-portal-users',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent implements OnInit {
  displayedColumns: string[] = [
    'userType',
    'name',
    'email',
    'client',
    'action',
  ];
  dataSource: MatTableDataSource<IUser>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  clients: IClient[];

  constructor(private store: Store, private userService: UserService) {}

  ngOnInit(): void {
    this.getUsers();
  }

  onFilterChange(value: IUsersFilterEvent): void {
    this.dataSource.filter = JSON.stringify(value);
  }

  private getUsers(): void {
    combineLatest([
      this.userService.getAll(),
      this.store.select(selectAllClients),
    ])
      .pipe(
        take(1),
        map(([users, clients]) => {
          this.clients = clients;
          this.setupUsersTable(users, clients);
        })
      )
      .subscribe();

    this.store.dispatch(ClientActions.fetchAllClients());
  }

  private filterPredicate(row: Partial<IUser>, filters: string): boolean {
    const filter: IUsersFilterEvent = JSON.parse(filters);
    if (
      (filter.userType || []).length < 1 &&
      !filter.name &&
      !filter.clientId
    ) {
      return true;
    }

    if (filter.userType.length && !filter.userType.includes(row.userType)) {
      return false;
    }

    if (filter.clientId && !row.clientIds.includes(filter.clientId)) {
      return false;
    }

    if (
      filter.name &&
      !`${row.firstName} ${row.lastName}`
        .toLowerCase()
        .includes(filter.name.toLowerCase()) &&
      !row.email.toLowerCase().includes(filter.name.toLowerCase())
    ) {
      return false;
    }

    return true;
  }

  private setupUsersTable(users: ConnectUser[], clients: IClient[]): void {
    const clientNamesDict = clients.reduce<Record<string, string>>(
      (prev, curr) => ({ ...prev, [curr.clientId]: curr.clientName }),
      {}
    );
    const usersWithClient = users.map((user) => ({
      ...user,
      clients: (user.clientIds || []).flatMap((c) =>
        clientNamesDict[c] ? [clientNamesDict[c]] : []
      ),
    }));

    this.dataSource = new MatTableDataSource(usersWithClient);
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = this.filterPredicate;
  }
}
