import { Injectable } from '@angular/core';
import { IExpert } from '@techspert-io/experts';
import { EngagementType } from '../models/engagements.models';

@Injectable()
export class EngagementsTitleService {
  public createEngagementTitle(
    type: EngagementType,
    opportunityName: string,
    expert: IExpert
  ): string {
    switch (type) {
      case 'call':
      case 'survey':
        return `${opportunityName} (${type})`;
      case 'data-review':
        return `${opportunityName} (data review)`;
      case 'payment':
        const name = `${expert.firstName || ''} ${
          expert.lastName || ''
        }`.trim();
        return `Payment - [${name}]`;
    }
  }
}
