<div class="flex flex-col gap-4 flex-1">
  <div class="flex justify-start content-center items-center w-full">
    <h3 class="flex-1">
      Segment search results:
      <span> viewing {{filteredExpertCount}} of {{experts.length}} </span>
    </h3>
    <div class="flex gap-4 items-center">
      <div class="flex justify-center content-center items-center h-full">
        <span>
          {{selection.selected.length || 0}} / {{experts.length || 0}}
        </span>
        <mat-icon>person</mat-icon>
      </div>
      <button mat-flat-button color="primary" [disabled]="!selection.selected.length" (click)="addSelected()">
        Add selected
      </button>
    </div>
  </div>

  <form [formGroup]="filterForm" class="flex flex-row gap-4">
    <mat-form-field class="flex-1">
      <mat-label>Filter</mat-label>
      <input matInput formControlName="filter" placeholder="Filter results" type="search">
    </mat-form-field>
    <mat-form-field class="flex-1">
      <mat-label>Expert segments</mat-label>
      <mat-select formControlName="expertSegments" multiple>
        <mat-option *ngFor="let segment of expertSegmentOptions" [value]="segment">
          {{ segment }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="flex-1">
      <mat-label>Segments</mat-label>
      <mat-select formControlName="segments" multiple>
        <mat-option *ngFor="let segment of segmentOptions" [value]="segment">
          {{ segment }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="flex-1">
      <mat-label>Opportunity</mat-label>
      <mat-select formControlName="opportunites" multiple>
        <mat-option *ngFor="let opp of opportunityOptions" [value]="opp">
          {{ opp }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="flex-1">
      <mat-label>Stages</mat-label>
      <mat-select formControlName="stages" multiple>
        <mat-option *ngFor="let stage of stageOptions" [value]="stage">
          {{ stage | phaseName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </form>

  <div [style.overflow]="'auto'" class="flex flex-col">
    <table mat-table matSort [dataSource]="dataSource" [trackBy]="trackBy">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? toggleAllRows() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element">
          <a [routerLink]="['/admin', 'experts', element.expertId]">
            {{ element.firstName || '' }} {{ element.lastName || '' }}</a>
        </td>
      </ng-container>
      <ng-container matColumnDef="emails">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Emails </th>
        <td mat-cell *matCellDef="let element">
          <ul class="flex flex-row flex-wrap gap-1 max-h-20 overflow-auto">
            <li class="ct-generic-chip ct-generic-chip--small" *ngFor="let email of element.opportunityEmails">
              <span class="ct-generic-chip__text">{{email}}</span>
            </li>
          </ul>
        </td>
      </ng-container>
      <ng-container matColumnDef="affiliations">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Affiliation </th>
        <td mat-cell *matCellDef="let element">
          <ul class="flex flex-row flex-wrap gap-1 max-h-20 overflow-auto">
            <li class="ct-generic-chip ct-generic-chip--small" *ngFor="let affiliation of element.affiliations">
              <span class="ct-generic-chip__text">{{affiliation}}</span>
            </li>
          </ul>
        </td>
      </ng-container>
      <ng-container matColumnDef="roles">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Role </th>
        <td mat-cell *matCellDef="let element">
          <ul class="flex flex-row flex-wrap gap-1 max-h-20 overflow-auto">
            <li class="ct-generic-chip ct-generic-chip--small" *ngFor="let role of element.roles">
              <span class="ct-generic-chip__text">{{role}}</span>
            </li>
          </ul>
        </td>
      </ng-container>
      <ng-container matColumnDef="expertise">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Expertise </th>
        <td mat-cell *matCellDef="let element">
          <ul class="flex flex-row flex-wrap gap-1 max-h-20 overflow-auto">
            <li class="ct-generic-chip ct-generic-chip--small" *ngFor="let expertise of element.expertise">
              <span class="ct-generic-chip__text">{{expertise}}</span>
            </li>
          </ul>
        </td>
      </ng-container>
      <ng-container matColumnDef="segment">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Segment </th>
        <td mat-cell *matCellDef="let element">
          <span>{{ element.expertSegment }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="opportunityName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Opportunity </th>
        <td mat-cell *matCellDef="let element">
          <span>{{ element.opportunityName }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="stage" [stickyEnd]="true">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Stage </th>
        <td mat-cell *matCellDef="let element">
          <span>{{ element.stage | phaseName }}</span>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          <div [style.padding]="'150px'" [style.text-align]="'center'">
            No experts
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>