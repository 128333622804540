<mat-form-field class="w-full">
    <mat-label data-test-id="chip-list-label">
        {{ label }}
    </mat-label>
    <mat-chip-grid #chipList class="ct-chipList" [formControl]="chipControl">
        <mat-chip-row data-test-id="chip-list-item" *ngFor="let item of list" [ngClass]="{
        'base-border': chipStyles && chipStyles[item],
        'solid-border': chipStyles && chipStyles[item] === 'solid-border',
        'dashed-border': chipStyles && chipStyles[item] === 'dashed-border'
      }" (removed)="removeItem(item)" [matTooltip]="
        getChipText(item)?.length > 60 ? getChipText(item) : null
      ">
            <span data-test-id="chip-list-item-text">
                {{ getChipText(item) }}
            </span>
            <button matChipRemove (click)="removeItem(item)" data-test-id="chip-list-item-remove">
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip-row>
    </mat-chip-grid>
    <input #input [placeholder]="placeholder" [matChipInputFor]="chipList" [matAutocomplete]="auto"
        [formControl]="optionsControl" (matChipInputTokenEnd)="addItem($event)" (paste)="onPaste($event)"
        data-test-id="chip-list-input" />
    <mat-hint *ngIf="hint">
        {{ hint }}
    </mat-hint>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addSelectedOption($event)">
        <mat-option *ngFor="let option of autoCompleteOptions$ | async" [value]="option"
            data-test-id="chip-list-option">
            {{ optionDisplayPropKey ? option[optionDisplayPropKey] : option }}
        </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="chipControl.hasError('required')" class="mat-error" data-test-id="chip-list-error">
        Required
    </mat-error>
</mat-form-field>