<div class="connection-container flex justify-between content-stretch items-stretch flex-col">
  <div class="flex flex-col justify-start content-stretch items-stretch w-full">
    <div class="flex justify-between content-start items-start w-full flex-row">
      <div class="connection-heading-container">
        <h2 class="card-category" data-test-id="view-connection-internal-name">{{data.callAction.internalName}}</h2>
      </div>
      <mat-icon class="close-icon" (click)="closeDialog()" data-test-id="view-connection-close">close</mat-icon>
    </div>

    <div [hidden]="isLoading" data-test-id="view-connection-conference-dialog-tabs">
      <mat-tab-group>
        <mat-tab label="Call details">
          <ng-container *ngTemplateOutlet="callDetails"></ng-container>
        </mat-tab>
        <mat-tab label="Email history">
          <ng-container *ngTemplateOutlet="emailHistory"></ng-container>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>

  <div *ngIf="isLoading" class="flex justify-center content-center items-center w-full flex-row">
    <app-loading-spinner></app-loading-spinner>
  </div>

  <div class="flex flex-1 flex-col justify-end content-stretch items-stretch w-full gap-[15px]">
    <ng-container *ngTemplateOutlet="callActions"></ng-container>
  </div>
</div>

<ng-template #callDetails>
  <mat-card class="call-info-container">
    <div class="call-info-row">
      <div class="flex-container">
        <div style="margin-right: 15px;">
          <mat-icon class="circle-icon mat-elevation-z3">info</mat-icon>
        </div>
        <div>
          <span data-test-id="view-connection-call-details-conference-state-label">Status</span>
          <br />
          <span style="font-weight: bold; color: darkgray" data-test-id="view-connection-call-details-conference-state">
            {{mapActionStatus(data.callAction.status)}}
          </span>
        </div>
      </div>
    </div>
    <div class="call-info-row">
      <div class="flex-container">
        <div style="margin-right: 15px;">
          <mat-icon class="circle-icon mat-elevation-z3">event</mat-icon>
        </div>
        <div data-test-id="view-connection-call-details-conference-date-time">
          <label>
            <span data-test-id="view-connection-call-details-conference-date">
              {{data.callAction.datetime | date:'fullDate'}}
            </span>
          </label>
          <br />
          <span style="font-weight: bold;" data-test-id="view-connection-call-details-conference-time">
            {{data.callAction.datetime | momentTimezone: 'HH:mm (z)'}}
          </span>
        </div>
      </div>
    </div>
    <div class="call-info-row">
      <div class="flex-container">
        <div style="margin-right: 15px;">
          <mat-icon class="circle-icon mat-elevation-z3">call</mat-icon>
        </div>
        <div>

          <ng-container *ngIf="data.callAction.joinMetadata">
            <label *ngIf="data.callAction.joinMetadata.weblink" class="flex align-middle gap-2 cursor-pointer font-bold"
              [copy-clipboard]="data.callAction.joinMetadata.weblink"
              data-test-id="view-connection-call-details-conference-link">
              <mat-icon class="-scale-75">content_copy</mat-icon> Guest/expert link
            </label>
            <label *ngIf="data.callAction.joinMetadata.weblink" class="flex align-middle gap-2 cursor-pointer font-bold"
              [copy-clipboard]="data.callAction.joinMetadata.hostlink"
              data-test-id="view-connection-call-details-conference-host-link">
              <mat-icon class="-scale-75">content_copy</mat-icon> Host link
            </label>
          </ng-container>
          <label *ngIf="!data.callAction.joinMetadata || !data.callAction.joinMetadata.weblink"
            data-test-id="view-connection-call-details-conference-link">
            No link
          </label>
          <span *ngIf="client && client.clientName" class="cursor-pointer" [copy-clipboard]="client.clientName"
            data-test-id="view-connection-call-details-client-name">
            {{client.clientName}}
          </span>
          <br />
          <span data-test-id="view-connection-call-details-conference-provider">
            Provider: {{data.callAction.callType}}
          </span>
          <br>
          <ng-container *ngIf="data.callAction.status !== 'cancelled' && 
            (data.callAction.callType === OpportunityConferenceProviders.ZoomTechspert || data.callAction.callType === OpportunityConferenceProviders.MeetTechspert)
             && data.callAction.zoomMetadata">
            <span class="cursor-pointer" [copy-clipboard]="data.callAction.zoomMetadata.zoomAccount"
              data-test-id="view-connection-call-details-zoom-account">
              Zoom account: {{data.callAction.zoomMetadata.zoomAccount}}
            </span>
            <br>
            <span class="cursor-pointer" [copy-clipboard]="data.callAction.zoomMetadata.zoomId"
              data-test-id="view-connection-call-details-zoom-meeting-id">
              Meeting ID: {{data.callAction.zoomMetadata.zoomId}}
            </span>
            <br>
            <span *ngIf="data.callAction.joinMetadata?.password" class="cursor-pointer"
              [copy-clipboard]="data.callAction.joinMetadata.password"
              data-test-id="view-connection-call-details-password">
              Password: {{data.callAction.joinMetadata.password}}
            </span>
            <br>
            <span *ngIf="expert" class="cursor-pointer" [copy-clipboard]="expert.expertProfileId"
              data-test-id="view-connection-call-details-expert-profile-id">
              Expert profile ID: {{expert.expertProfileId}}
            </span>
            <br>
          </ng-container>
          <span data-test-id="view-connection-call-details-recording-on">
            {{data.callAction.isRecordingOn ? 'Recorded line' : 'Non-recorded line'}}
          </span>
          <br />
        </div>
      </div>
    </div>
    <div class="call-info-row" *ngIf="client && opportunity">
      <div class="flex-container">
        <div style="margin-right: 15px;">
          <mat-icon class="circle-icon mat-elevation-z3">person</mat-icon>
        </div>
        <div *ngIf="client && opportunity">
          <div *ngIf="!opportunity.clientContacts" data-test-id="view-connection-call-details-no-client-details">
            No client details available
          </div>
          <div *ngIf="!expert" data-test-id="view-connection-call-details-no-expert-details">
            No expert details available
          </div>

          <div *ngIf="expert" class="client-name" style="width: 100%; margin-bottom: 5px;">
            <span data-test-id="view-connection-expert-details-label">Expert</span>
            <br />
            <span style="font-weight: bold; color: darkgray" data-test-id="view-connection-expert-name">
              {{expert | expertName}}
            </span> |
            <label data-test-id="view-connection-call-details-expert-email">{{expert.primaryEmail}}</label>
          </div>

          <hr />

          <div *ngIf="opportunity.clientContacts && opportunity.clientContacts.length > 0"
            style="max-height: 300px; overflow: auto;">
            <span data-test-id="view-connection-call-details-client-contact-details-label">Client</span>
            <br />
            <div class="client-name" style="width: 100%; margin-bottom: 5px;"
              *ngFor="let contact of opportunity.clientContacts"
              data-test-id="view-connection-call-details-client-contact">
              <span style="font-weight: bold; color: darkgray"
                data-test-id="view-connection-call-details-client-contact-name">
                {{contact.firstName}} {{contact.lastName}}
              </span> |
              <label data-test-id="view-connection-call-details-client-contact-email">{{contact.email}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</ng-template>

<ng-template #emailHistory>
  <mat-card class="call-info-container">
    <div [hidden]="!dataSource.data.length">
      <table class="ct-noPaddingTable" mat-table matSort matSortActive="date" matSortDirection="desc"
        [dataSource]="dataSource" data-test-id="view-connection-email-history-table">
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef [style.width]="'25%'" mat-sort-header>Sent Date</th>
          <td mat-cell *matCellDef="let element" data-test-id="view-connection-email-history-table-sent-date">
            {{element.date | date: 'short'}}
          </td>
        </ng-container>
        <ng-container matColumnDef="recipient">
          <th mat-header-cell *matHeaderCellDef [style.width]="'40%'" mat-sort-header>Recipient</th>
          <td mat-cell *matCellDef="let element">
            <div class="flex justify-start content-center items-center gap-[5px]">
              <mat-icon *ngIf="element.recipient === expert?.primaryEmail" [matTooltip]="'expert'">
                biotech
              </mat-icon>
              <mat-icon *ngIf="element.recipient !== expert?.primaryEmail" [matTooltip]="'non-expert'">
                corporate_fare
              </mat-icon>
              <div data-test-id="view-connection-email-history-table-recipient-email">
                {{element.recipient}}
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="emailType">
          <th mat-header-cell *matHeaderCellDef [style.width]="'25%'" mat-sort-header>Type</th>
          <td mat-cell *matCellDef="let element" data-test-id="view-connection-email-history-table-email-type">
            {{element.emailType}}
          </td>
        </ng-container>
        <ng-container matColumnDef="statusLabel">
          <th mat-header-cell *matHeaderCellDef [style.width]="'10%'" mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let element" data-test-id="view-connection-email-history-table-email-state">
            <mat-icon
              [ngClass]="{'fail': element.statusClass==='fail' , 'pending': element.statusClass==='pending', 'success': element.statusClass==='success'}"
              [matTooltip]="element.statusLabel">{{element.statusIcon}}</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"
          data-test-id="view-connection-email-history-table-row">
        </tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5]" showFirstLastButtons aria-label="Select page of call emails">
      </mat-paginator>
    </div>

    <div *ngIf="!dataSource.data.length" data-test-id="view-connection-email-history-no-emails"
      class="flex justify-center content-center items-center flex-row">
      No call email data found.
      Please note this system may not detect emails sent before the 9th of May 2022.
    </div>
  </mat-card>
</ng-template>

<ng-template #callActions>
  <div *ngIf="!data.isActionsDisabled && opportunity && client && expert && data.callAction; else loader"
    class="flex flex-col gap-2">
    <button *ngIf="data.callAction.status === 'request'" mat-raised-button color="primary" class="update-conference"
      (click)="updateConference('schedule')" data-test-id="view-connection-schedule-conference">
      Schedule conference
    </button>
    <button *ngIf="showRescheduleStatuses.includes(data.callAction.status)" mat-raised-button color="primary"
      class="update-conference" (click)="updateConference('reschedule')"
      data-test-id="view-connection-reschedule-conference">
      Reschedule conference
    </button>
    <button *ngIf="data.callAction.status !== 'request'" mat-stroked-button class="update-conference"
      (click)="updateConference('updateEmail')" data-test-id="view-connection-email-an-update">
      Email an update
    </button>
    <button *ngIf="showCancelStatuses.includes(data.callAction.status)" mat-stroked-button color="warn"
      class="update-conference" (click)="updateConference('cancel')" data-test-id="view-connection-cancel-conference">
      Cancel conference
    </button>
    <button *ngIf="showCompleteStatuses.includes(data.callAction.status)" mat-stroked-button color="primary"
      class="update-conference" (click)="updateConference('complete')"
      data-test-id="view-connection-confirm-conference-complete">
      Confirm conference complete
    </button>
    <button *ngIf="showCancelStatuses.includes(data.callAction.status)" mat-stroked-button color="primary"
      class="update-conference" data-test-id="view-connection-edit-emergency-contact"
      (click)="openUpdateEmergencyContact()">
      Edit emergency contact
    </button>
  </div>
  <ng-template #loader>
    <app-loading-spinner></app-loading-spinner>
  </ng-template>
</ng-template>