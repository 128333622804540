import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, ValidatorFn } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ToastService } from '@techspert-io/user-alerts';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { debounceTime, filter, map, takeUntil, tap } from 'rxjs/operators';

export type ChipListStyle = 'solid-border' | 'dashed-border';

@Component({
  selector: 'ct-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss'],
})
export class ChipListComponent implements OnInit, OnDestroy, OnChanges {
  private clipboard = navigator.clipboard;
  private destroy$ = new Subject();
  private autoComplete$ = new BehaviorSubject<unknown[]>([]);

  @Input() label: string;
  @Input() list: unknown[] = [];
  @Input() placeholder: string;
  @Input() displayPropKey: string;
  @Input() optionDisplayPropKey: string;
  @Input() autoCompleteOptions: unknown[] = [];
  @Input() filterAutoCompleteOptions = true;
  @Input() allowOptionsOnly: boolean;
  @Input() disabled: boolean;
  @Input() splitByPipe: boolean = false;
  @Input() chipStyles?: Record<string, ChipListStyle> = {};
  @Input() validators?: ValidatorFn | ValidatorFn[];
  @Input() hint?: string;
  @Input() debounce = 500;

  @Output() addItemSignal = new EventEmitter<unknown>();
  @Output() removeItemSignal = new EventEmitter<unknown>();
  @Output() listChange = new EventEmitter<unknown[]>();
  @Output() inputChange = new EventEmitter<unknown>();

  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  autoCompleteOptions$: Observable<unknown>;

  chipControl = new FormControl(this.list);
  optionsControl = new FormControl();

  constructor(private toastService: ToastService) {}

  ngOnInit(): void {
    this.optionsControl.valueChanges
      .pipe(
        filter(Boolean),
        debounceTime(this.debounce),
        tap((value) => this.inputChange.emit(value)),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.autoCompleteOptions$ = combineLatest([
      this.optionsControl.valueChanges,
      this.autoComplete$,
    ]).pipe(
      map(([searchTerm, options]) =>
        typeof searchTerm === 'string'
          ? this.filterOptions(searchTerm, options)
          : options
      ),
      takeUntil(this.destroy$)
    );

    this.chipControl.setValidators(this.validators);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.autoCompleteOptions) {
      this.autoComplete$.next(changes.autoCompleteOptions.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  addItem(event: MatChipInputEvent): void {
    if (!this.allowOptionsOnly) {
      if (this.optionsControl.valid) {
        const input = event.input;
        const value = event.value;
        this.addItemSignal.emit(value);
        this.optionsControl.setValue(null);
        input.value = '';
      } else {
        this.toastService.sendMessage(
          `Invalid ${this.displayPropKey}`,
          'error'
        );
      }
    }
  }

  removeItem(item: unknown): void {
    this.removeItemSignal.emit(item);
  }

  onPaste(event: ClipboardEvent): void {
    if (this.splitByPipe) {
      event.preventDefault();
      this.clipboard.readText().then((text: string) => {
        const terms = Array.from(new Set([...this.list, ...text.split('| ')]));
        this.list = terms;
        this.listChange.emit(terms);
        this.toastService.sendMessage(
          'Attempted to convert pasted content',
          'success'
        );
      });
    }
  }

  addSelectedOption(event: MatAutocompleteSelectedEvent): void {
    this.addItemSignal.emit(event.option.value);
    this.input.nativeElement.value = '';
    this.optionsControl.setValue(null);
    this.chipControl.markAsUntouched();
    this.chipControl.updateValueAndValidity();
  }

  getChipText(item: unknown): string {
    return this.displayPropKey ? item[this.displayPropKey] : item;
  }

  private filterOptions(searchTerm: string, options: unknown[]): unknown[] {
    if (!this.filterAutoCompleteOptions) {
      return options;
    }

    return options.filter((option) =>
      JSON.stringify(option)
        .toLowerCase()
        .includes(searchTerm.toLowerCase().trim())
    );
  }
}
