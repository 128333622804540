import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CognitoAuthService } from '@techspert-io/auth';
import { IClient } from '@techspert-io/clients';
import { IOpportunity } from '@techspert-io/opportunities';
import { ToastService } from '@techspert-io/user-alerts';
import { ExportToCsv } from 'export-to-csv';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { EMPTY, Subject } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  finalize,
  map,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  ConfirmationDialogComponent,
  IConfirmationDialogData,
} from '../../shared/patterns/confirmation-dialog/confirmation-dialog.component';
import { EmailService } from '../../shared/services/email.service';
import { IAddNodeSignal } from './models/knowledge-graph.models';
import {
  IFalconSearchQuery,
  ILinkedInEnrichmentQuery,
  INPIEnrichmentQuery,
  ISearchQuery,
  SearchQuery,
} from './models/query';
import {
  ISearchDisplayExpert,
  ISelectOpportunityDialogData,
} from './models/search-models';
import { ResultsDisplayFilterService } from './results-display-filter/results-display-filter.service';
import { SelectOpportunityDialogComponent } from './select-opportunity-dialog/select-opportunity-dialog.component';
import {
  ExpertFalconSearchService,
  IFalconSearchExpert,
} from './services/expert-falcon-search.service';
import { SearchService } from './services/search.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
})
export class SearchComponent implements OnInit, OnDestroy {
  @Output() dialogModeUpdatedOpportunitySignal = new EventEmitter();
  @Input() dialogMode: boolean = false;
  @Input() opportunity: IOpportunity = null;
  @Input() client: IClient = null;
  @Input() searchName?: string;
  @Input() query?: SearchQuery;
  selectedCount: number;
  selectAllToggle: boolean = false;
  selectionConfiguration: 'emails' | 'all' = 'all';
  experts: ISearchDisplayExpert[] = [];
  erroredUrls: string[] = [];
  filteredExperts: ISearchDisplayExpert[] = [];
  falconSearchExperts: IFalconSearchExpert[] = [];
  showSearchLoader: boolean = false;
  viewSummary: boolean = false;
  viewQuery: boolean = true;
  queryExpertTotal: number;
  showTaxonomy: boolean = true;
  addTerms: string[] = [];

  private destroy$ = new Subject();
  maskedMode = false;

  get opportunityName(): string {
    return this.opportunity ? `(${this.opportunity.opportunityName})` : '';
  }

  constructor(
    public router: Router,
    public searchService: SearchService,
    public emailService: EmailService,
    public cognitoAuthService: CognitoAuthService,
    public dialog: MatDialog,
    public toastService: ToastService,
    private filters: ResultsDisplayFilterService,
    private falconSearchService: ExpertFalconSearchService,
    private route: ActivatedRoute,
    private gaService: GoogleAnalyticsService
  ) {}

  ngOnInit(): void {
    this.route.data
      .pipe(
        filter((d) => !!d.metaData),
        map((d) => d.metaData),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        this.client = data.client;
        this.opportunity = data.opportunity;
        this.query = data.query;
        this.dialogMode = true;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  @HostListener('window:keypress', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key === '\u0013' && event.shiftKey && event.ctrlKey) {
      this.maskedMode = !this.maskedMode;
    }
  }

  public assignSelectionConfiguration(option: 'emails' | 'all'): void {
    this.selectionConfiguration = option;
  }

  public runSearch(
    payload:
      | ISearchQuery
      | INPIEnrichmentQuery
      | ILinkedInEnrichmentQuery
      | IFalconSearchQuery
  ): void {
    this.filters.reset();
    this.viewSummary = false;
    this.query = payload;
    this.showTaxonomy = false;

    if (
      payload.service === 'pdl-enrichment' ||
      payload.service === 'npi-enrichment'
    ) {
      return this.openSendExpertsDialog();
    }

    this.showSearchLoader = true;
    if (payload.service === 'falcon-search') {
      this.runFalconSearch(payload).subscribe();
    } else {
      this.runSearches(payload).subscribe();
    }
  }

  public clearExperts(): void {
    this.filters.reset();
    this.viewSummary = false;
    this.experts = [];
    this.filteredExperts = [];
    this.selectedCount = 0;
    this.selectAllToggle = false;
    this.query = undefined;
    this.queryExpertTotal = 0;
  }

  public filterExperts(term: string): void {
    this.filteredExperts = this.experts.filter((expert) =>
      Object.values(expert.source).some((value) =>
        JSON.stringify(value).toLowerCase().includes(term.toLowerCase())
      )
    );
  }

  public filterExpertsAdv(filterExperts: ISearchDisplayExpert[]): void {
    this.filteredExperts = filterExperts;
    this.selectedCount = this.calculateSelectedCount(this.filteredExperts);
  }

  public toggleSelectExpert(id: string): void {
    this.filteredExperts = this.filteredExperts.map((expert) => {
      if (expert.id === id) {
        expert.selected = !expert.selected;
      }
      return expert;
    });
    this.selectedCount = this.calculateSelectedCount(this.filteredExperts);
  }

  public toggleSelectExperts(): void {
    this.selectAllToggle = !this.selectAllToggle;
    if (this.selectionConfiguration === 'emails') {
      this.toggleSelectEmailsOnly();
    } else {
      this.toggleSelectAll();
    }
    this.selectedCount = this.calculateSelectedCount(this.filteredExperts);
  }

  public downloadExpertListCsv(): void {
    const date = new Date().toISOString().slice(0, 10);
    const formattedExperts = this.filteredExperts.map((e) =>
      this.formatForCSV(e.source)
    );

    const csvExporter = new ExportToCsv({
      fieldSeparator: ',',
      filename: `Expert-list-${date}`,
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: `Expert-list-${date}`,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    });
    csvExporter.generateCsv(formattedExperts);
  }

  public openSendExpertsDialogForFalconSearch(expertIds: string[]): void {
    if (this.query.service !== 'falcon-search') {
      return;
    }

    const dialogRef = this.dialog.open<
      SelectOpportunityDialogComponent,
      ISelectOpportunityDialogData
    >(SelectOpportunityDialogComponent, {
      data: {
        selectedCount: expertIds.length,
        experts: [],
        dialogMode: this.dialogMode,
        query: {
          ...this.query,
          expertIds,
        },
        opportunity: this.opportunity,
        client: this.client,
      },
      width: '600px',
      height: 'max-content',
    });

    this.handleSendDialogClose(dialogRef.componentInstance);
  }

  public openSendExpertsDialog(): void {
    if (this.query.service === 'falcon-search') {
      return;
    }

    const selectedExperts = this.filteredExperts.filter(
      (expert) => expert.selected
    );
    const selectedCount =
      (this.query.service === 'pdl-enrichment' ||
        this.query.service === 'npi-enrichment') &&
      (this.query.pdlEnrichmentService === 'linkedin_urls' ||
        this.query.pdlEnrichmentService === 'npi_numbers')
        ? this.query.attributes.length
        : selectedExperts.length;

    const sendExpertsDialogData: ISelectOpportunityDialogData = {
      selectedCount,
      experts: selectedExperts,
      dialogMode: this.dialogMode,
      opportunity: this.opportunity,
      client: this.client,
      query: this.query,
      source: this.query.service,
    };
    if (this.searchName) {
      sendExpertsDialogData.searchName = this.searchName;
    }
    const dialogRef = this.dialog.open<
      SelectOpportunityDialogComponent,
      ISelectOpportunityDialogData
    >(SelectOpportunityDialogComponent, {
      // add search name for extend search
      data: sendExpertsDialogData,
      width: '600px',
      height: 'max-content',
    });
    this.handleSendDialogClose(dialogRef.componentInstance);
  }

  public onAddNode(addNodeSignal: IAddNodeSignal): void {
    this.addTerms = addNodeSignal.options.includeSynonyms
      ? [addNodeSignal.node.name, ...addNodeSignal.node.synonyms]
      : [addNodeSignal.node.name];
  }

  private handleSendDialogClose(
    componentInstance: SelectOpportunityDialogComponent
  ) {
    componentInstance.dialogModeUpdatedOpportunitySignal.subscribe(
      (updatedOpportunityPayload) => {
        this.query = null;
        this.searchName = null;
        this.dialogModeUpdatedOpportunitySignal.emit(updatedOpportunityPayload);
        this.showSearchLoader = false;
      }
    );
  }

  private runSearches(payload: ISearchQuery) {
    return this.searchService.search(payload).pipe(
      tap((data) => {
        if (Array.isArray(data.experts) && !data.experts.length) {
          this.toastService.sendMessage(
            'No experts found for your query. Please try a different query.',
            'error'
          );
        }

        this.queryExpertTotal = data.total;
        this.experts = data.experts;
        this.filteredExperts = data.experts;
        this.selectedCount = this.calculateSelectedCount(this.filteredExperts);
      }),
      concatMap((data) => {
        if (data.total <= 5000) {
          return EMPTY;
        }

        this.gaService.gtag('event', 'view', {
          event_category: 'broad_query_warning',
          userId: this.cognitoAuthService.loggedInUser.connectId,
          total: `${data.total}`,
        });

        return this.dialog
          .open<ConfirmationDialogComponent, IConfirmationDialogData, boolean>(
            ConfirmationDialogComponent,
            {
              data: {
                title: 'Broad query warning',
                messages: [
                  `Query matches over 5000 possible profiles (${data.total}), which may include some experts that are less suitable for the project.`,
                  'Do you wish to proceed?',
                ],
                positiveButtonText: 'Yes',
                negativeButtonText: 'No',
                isDangerous: true,
              },
            }
          )
          .afterClosed()
          .pipe(
            tap((confirmed) =>
              confirmed
                ? this.gaService.gtag('event', 'click', {
                    event_category: 'broad_query_warning',
                    userId: this.cognitoAuthService.loggedInUser.connectId,
                    total: `${data.total}`,
                  })
                : this.clearExperts()
            )
          );
      }),
      catchError((error) => {
        if (error.message) {
          this.toastService.sendMessage(error.message, 'error');
        }

        return EMPTY;
      }),
      finalize(() => (this.showSearchLoader = false))
    );
  }

  private calculateSelectedCount(experts: ISearchDisplayExpert[]): number {
    return experts.filter((expert) => expert.selected).length;
  }

  private toggleSelectAll(): void {
    this.filteredExperts = this.filteredExperts.map((e) => {
      e.selected = this.selectAllToggle;
      return e;
    });
  }

  private toggleSelectEmailsOnly(): void {
    if (this.selectAllToggle) {
      this.filteredExperts = this.filteredExperts.map((e) => {
        if (e.source.opportunityEmails?.length > 0) {
          e.selected = this.selectAllToggle;
        }
        return e;
      });
    } else {
      this.toggleSelectAll();
    }
  }

  private runFalconSearch(payload: IFalconSearchQuery) {
    return this.falconSearchService
      .get({
        searchTerms: payload.searchTerms,
        countries: payload.countries,
        size: payload.size,
      })
      .pipe(
        tap((data) => {
          this.falconSearchExperts = data;
          if (!data.length) {
            this.toastService.sendMessage(
              'No experts found for your query. Please try a different query.',
              'error'
            );
          }
        }),
        catchError((err) => {
          this.falconSearchExperts = [];
          this.toastService.sendMessage(
            err.error?.message || err.message || JSON.stringify(err),
            'error'
          );
          return EMPTY;
        }),
        finalize(() => (this.showSearchLoader = false))
      );
  }

  private formatForCSV(
    expert: ISearchDisplayExpert['source']
  ): Record<string, string> {
    return Object.entries(expert).reduce((prev, [key, val]) => {
      if (key === 'opportunityEmails') {
        return { ...prev, otherEmails: val.join(', ') };
      }
      if (Array.isArray(val)) {
        return { ...prev, [key]: val.map((d) => d).join(', ') };
      }
      if (key === 'searchExpertData' && typeof val === 'object') {
        return {
          ...prev,
          searchExpertId: val.id || '',
          searchTrackingId: val.trackingId || '',
        };
      }
      return { ...prev, [key]: val || '' };
    }, {});
  }
}
