<div class="expert-underlay"
  [ngClass]="{'blocked-container': expert.blocked, 'show-affiliations-extra-margin': showAffiliations}"
  *ngIf="!openFilter" [attr.data-test-id]="'expert-list-item-' + expert.expertId">
  <div (appClickOutside)="closeActions()" class="expert-underlay-actions">
    <div class="expert-underlay-action">
      <mat-icon *ngIf="expert.rejection.admin || expert.rejection.client || expert.rejection.expert"
        (click)="emitToggleAdminRejectStatusSignal()" class="pull-left">thumb_up
      </mat-icon>
      <mat-icon *ngIf="!expert.rejection.admin && !expert.rejection.client && !expert.rejection.expert"
        (click)="emitToggleAdminRejectStatusSignal()">
        thumb_down</mat-icon>
    </div>
    <div class="expert-underlay-action">
      <mat-icon *ngIf="expert.blocked" (click)="emitToggleBlockExpertSignal({blocked: false, index: index})"
        class="largeIcon unBlocker" title="Unblock expert">rotate_left</mat-icon>
      <mat-icon *ngIf="!expert.blocked" (click)="emitToggleBlockExpertSignal({blocked: true, index: index})"
        class="largeIcon blocker" title="Block expert">block</mat-icon>
    </div>
    <div *ngIf="expert.sites?.length" class="expert-underlay-action">
      <a [href]="expert.sites[0]" target="_blank">
        <mat-icon>school</mat-icon>
      </a>
    </div>
  </div>
  <div [ngClass]="{'reduce-width': showActions}" data-test-id="expert-list-detail" class="expert-overlay">
    <div class="select-grid-item">
      <span class="check-box" *ngIf="expert.isSelectable" data-test-id="expert-list-selected-check-box"
        (click)="emitSelectExpertSignal(expert)">
        <mat-icon *ngIf="!expert.isSelected">check_box_outline_blank</mat-icon>
        <mat-icon *ngIf="expert.isSelected" class="checked">check_box</mat-icon>
      </span>
      <span class="check-box" *ngIf="!expert.isSelectable">
        <mat-icon class="disabled">highlight_off</mat-icon>
      </span>
      <mat-icon (click)="toggleShowActions($event)" [ngClass]="showActions ? 'rotate-opposite' : ''"
        class="open-actions">arrow_right_alt</mat-icon>
    </div>
    <div class="identity-grid-item">
      <div class="flex justify-start content-center items-center flex-row gap-[5px]">
        <span class="expert-name">
          <div class="flex justify-start content-center items-center flex-row gap-0.5">
            <mat-icon *ngIf="expert.blocked" class="blocked" [matTooltip]="'Blocked'"
              data-test-id="expert-blocked-icon">
              block
            </mat-icon>
            <mat-icon *ngIf="expert.rejection.expert" fontSet="material-icons-outlined" class="rejected"
              [matTooltip]="'Expert rejected'" data-test-id="expert-declined-project-icon">
              do_not_disturb_off
            </mat-icon>
            <mat-icon *ngIf="expert.rejection.admin" class="rejected" [matTooltip]="'Admin rejected'"
              data-test-id="expert-admin-rejected-icon">
              clear
            </mat-icon>
            <mat-icon *ngIf="expert.rejection.client" class="rejected" [matTooltip]="'Client rejected'"
              data-test-id="expert-client-rejected-icon">
              thumb_down
            </mat-icon>
            <mat-icon *ngIf="expert.clientApproved" class="approvedIcon" [matTooltip]="'Client approved'"
              data-test-id="expert-client-approved-icon">
              check_circle_outline
            </mat-icon>
            <mat-icon *ngIf="expert.onHold" class="onHoldIcon" [matTooltip]="'On hold'">pause_circle</mat-icon>
            <div data-test-id="expert-list-expert-name">
              {{expert.firstName}}
              {{expert.firstName && expert.lastName ? ' ' : ''}}
              {{expert.lastName}}
            </div>
          </div>
          <div *ngIf="expert.firstNameAlias || expert.lastNameAlias">
            ({{expert.firstNameAlias}}
            {{expert.firstNameAlias && expert.lastNameAlias ? ' ' : ''}}
            {{expert.lastNameAlias}})
          </div>
        </span>
        <div class="flex flex-row">
          <mat-icon class="ct-engagementIndicator"
            matTooltip="{{engagement.engagementType}} {{engagement.dateOfEngagement | date : 'shortDate'}} {{engagement.amountOwed}}{{engagement.currency}} {{engagement.paymentType === 'thirdParty' ? 'Third party: ' + engagement.paymentProvider : engagement.paymentType + ' ' + engagement.paymentStatus}}"
            *ngFor="let engagement of expert.engagements" [style.color]="getEngagementStatusColor(engagement)">
            attach_money</mat-icon>
        </div>
      </div>
      <div class="source-search-code-row">
        <span *ngIf="expert.source" [matTooltip]="expert.source | sourceDisplayName" [ngClass]="{
          'pdl-commercial-pill': expert.source === 'pdl-commercial',
          'pdl-commercial-pill': expert.source === 'pdl-enrichment',
          'deep-next-pill': expert.source === 'deep-next',
          'deep-pill': expert.source === 'deep',
          'deep-pill': expert.source === 'deep3',
          'hist-pill': expert.source === 'historical',
          'netw-pill': expert.source === 'network',
          'manu-pill': expert.source === 'manualUpload',
          'manu-third-party': expert.source === 'third-party',
        }" class="source-pill" data-test-id="expert-list-expert-source">
          <ng-container>{{expert.source | sourceDisplayName:'true'}}</ng-container>
        </span>
        <span class="search-id" data-test-id="expert-list-expert-search-id"> - {{expert.searchId}}</span>
      </div>
    </div>

    <div class="details-grid-item">
      <app-country-box [countries]="expert.countries" [verifiedCountry]="expert.country"></app-country-box>
      <app-email-box class="email-box-width" [emails]="expert.opportunityEmails" [primaryEmail]="expert.primaryEmail">
      </app-email-box>
      <app-availability-box
        *ngIf="expert.connectPhase === 'outreach' || expert.connectPhase === 'sentToClient' || expert.connectPhase === 'accepted'"
        [timezoneName]="expert.timezoneName" [requestedAnotherTime]="expert.requestedAnotherTime"
        [availabilities]="expert.availabilities" [callTime]="expert.callTime">
      </app-availability-box>
      <button [routerLink]="['/admin', 'experts', expert.expertId]" [queryParams]="{tab: 'Conferences'}"
        class="button button--secondary schedule-button"
        *ngIf="expert.connectPhase === 'accepted' && expert.currency && expert.primaryEmail"
        data-test-id="opportunity-schedule-call">Schedule Call
      </button>
      <div style="display: flex" matTooltip="Need an expert currency & poc email"
        *ngIf="expert.connectPhase === 'accepted' && (!expert.currency || !expert.primaryEmail)">
        <button [disabled]="true" class="button button--secondary schedule-button"
          data-test-id="opportunity-schedule-call">
        </button>
      </div>
      <app-scheduled-box *ngIf="expert.connectPhase === 'scheduled' || expert.connectPhase === 'completed'"
        [expert]="expert">
      </app-scheduled-box>
      <app-screener-box (click)="emitShowScreenerResults()"
        *ngIf="expert.screenerConfig && (expert.connectPhase === 'screener' || expert.rejection.admin || expert.rejection.expert)"
        [screenerConfig]="expert.screenerConfig">
      </app-screener-box>
      <app-compliance-training-box
        *ngIf="opportunity.projectType === 'Qual' && client.features?.expertTrainingRequired && (expert.connectPhase === 'screener')"
        [complianceTraining]="expert.training">
      </app-compliance-training-box>
    </div>
    <div class="expand-grid-item">
      <a [routerLink]="['/admin', 'experts', expert.expertId]">
        <mat-icon class="expand-icon" id="fullscreen-icon" data-test-id="opportunity-open-expert">
          fullscreen
        </mat-icon>
      </a>
    </div>
  </div>
</div>

<div *ngIf="openFilter" [ngClass]="showActions ? 'reduce-width' : ''" class="ghost-expert-details"
  data-test-id="expert-list-search-result">
  <div class="phase-div">
    <span class="phase-box">
      <span data-test-id="expert-list-search-result-expert-phase">{{expert.connectPhase}}</span>
    </span>
  </div>
  <div class="identity-grid-item reduce-opacity">
    <span class="expert-name" data-test-id="expert-list-search-result-expert-name">{{expert | expertName}}</span>
  </div>

  <div class="details-grid-item reduce-opacity">
    <app-country-box [countries]="expert.countries" [verifiedCountry]="expert.country"
      data-test-id="expert-list-search-result-expert-countries"></app-country-box>
    <div *ngIf="expert.rejection.admin || expert.rejection.client || expert.rejection.expert"
      class="reason-for-rejection-div">
      <span class="rejection-label">Reason for rejection</span>
      <mat-icon matTooltip="{{expert.reasonForRejection ? expert.reasonForRejection : 'Rejected Expert'}}">info
      </mat-icon>
    </div>
    <app-email-box [emails]="expert.opportunityEmails" [primaryEmail]="expert.primaryEmail"></app-email-box>
    <app-availability-box
      *ngIf="expert.connectPhase === 'outreach' || expert.connectPhase === 'sentToClient' || expert.connectPhase === 'accepted'"
      [timezoneName]="expert.timezoneName" [availabilities]="expert.availabilities" [callTime]="expert.callTime">
    </app-availability-box>
    <app-scheduled-box [expert]="expert" *ngIf="expert.connectPhase === 'scheduled'">
    </app-scheduled-box>
  </div>
  <div class="go-to-expert-div">
    <button (click)="emitNavigateToExpertSignal(expert)" class="button secondary"
      data-test-id="expert-list-search-result-go-to-expert">
      Go to expert
    </button>
  </div>
</div>