<form [formGroup]="userForm" (submit)="submitForm()" class="flex flex-col gap-10">
  <div>
    <mat-radio-group class="radio-group" formControlName="userType" data-test-id="user-form-user-type-tiles">
      <div class="option-selector" role="option" *ngFor="let userType of userTypes"
        [attr.data-test-id]="'user-form-user-type-tile-' + userType.value">
        <span class="title" data-test-id="user-form-user-type-name">{{ userType.name }}</span><br>
        <span class="description" data-test-id="user-form-user-type-description">{{ userType.description }}</span>
        <mat-radio-button class="radio-item" [value]="userType.value"
          data-test-id="user-form-user-type-select"></mat-radio-button>
      </div>
    </mat-radio-group>

    <mat-error *ngIf="userTypeControl.errors && isSubmitted" data-test-id="user-form-user-type-error">
      User type is required
    </mat-error>
  </div>

  <ng-container *ngIf="isUserTypeSelected" data-test-id="user-form-user-detail-form">
    <div class="flex flex-col gap-1">
      <div class="flex flex-row gap-4">
        <mat-form-field class="flex-1">
          <mat-label data-test-id="user-form-first-name-label">First name</mat-label>
          <input type="text" matInput placeholder="First name" formControlName="firstName"
            data-test-id="user-form-first-name">
          <mat-error data-test-id="user-form-first-name-error"> Required </mat-error>
        </mat-form-field>

        <mat-form-field class="flex-1">
          <mat-label data-test-id="user-form-last-name-label">Last name</mat-label>
          <input type="text" matInput placeholder="Last name" formControlName="lastName"
            data-test-id="user-form-last-name">
          <mat-error data-test-id="user-form-last-name-error"> Required </mat-error>
        </mat-form-field>
      </div>

      <mat-form-field class="flex-1">
        <mat-label data-test-id="user-form-email-label">Email address</mat-label>
        <input type="text" matInput placeholder="Email address" formControlName="emailAddress"
          data-test-id="user-form-email">
        <mat-error data-test-id="user-form-email-error">
          <ng-container *ngIf="userForm.get('emailAddress').errors?.email">
            Enter a valid email
          </ng-container>
          <ng-container *ngIf="userForm.get('emailAddress').errors?.required">
            Required
          </ng-container>
        </mat-error>
      </mat-form-field>

      <div class="flex flex-row gap-4">
        <mat-form-field class="flex-1">
          <mat-label data-test-id="user-form-country-label">Select the country</mat-label>
          <mat-select formControlName="country" data-test-id="user-form-country">
            <mat-option *ngFor="let country of countries" [value]="country.abbreviation"
              data-test-id="user-form-country-option">
              {{country.country}}
            </mat-option>
          </mat-select>
          <mat-error data-test-id="user-form-country-error"> Required </mat-error>
        </mat-form-field>

        <mat-form-field class="flex-1">
          <mat-label data-test-id="user-form-timezone-label">Select the timezone</mat-label>
          <mat-select formControlName="timezone" data-test-id="user-form-timezone">
            <mat-option *ngFor="let zone of possibleZones" [value]="zone.name" data-test-id="user-form-timezone-option">
              {{zone.offset}} {{zone.abbr}} {{zone.name}}
            </mat-option>
          </mat-select>
          <mat-error data-test-id="user-form-timezone-error"> Required </mat-error>
        </mat-form-field>
      </div>

      <ng-container *ngIf="requiresClient">
        <app-user-client-control [clients]="clients" formControlName="clients" [multiple]="true"
          data-test-id="user-form-clients">
        </app-user-client-control>
      </ng-container>

      <ng-container *ngIf="showPMExtraFields || showNDExtraFields || showNDManagerExtraFields">
        <div class="flex flex-row gap-4">
          <mat-form-field class="flex-1">
            <mat-label data-test-id="user-form-job-title-label">Job title</mat-label>
            <mat-select placeholder="Job title" formControlName="jobTitle" data-test-id="user-form-job-title">
              <mat-option *ngFor="let job of jobTitles" [value]="job" data-test-id="user-form-job-title-option">
                {{job | jobTitle}}
              </mat-option>
            </mat-select>
            <mat-error data-test-id="user-form-job-title-error"> Required </mat-error>
          </mat-form-field>

          <mat-form-field class="flex-1">
            <mat-label data-test-id="user-form-phone-number-label">Phone number</mat-label>
            <input type="text" matInput placeholder="Phone number" formControlName="phoneNumber"
              data-test-id="user-form-phone-number">
          </mat-form-field>
        </div>

        <div class="flex flex-row gap-4">
          <mat-form-field class="flex-1">
            <mat-label data-test-id="user-form-office-location-label">Office location</mat-label>
            <mat-select placeholder="Office location" formControlName="officeLocation"
              data-test-id="user-form-office-location">
              <mat-option *ngFor="let location of officeLocations" [value]="location"
                data-test-id="user-form-office-location-option">
                {{location | titlecase}}
              </mat-option>
            </mat-select>
            <mat-error data-test-id="user-form-office-location-error"> Required </mat-error>
          </mat-form-field>

          <mat-form-field class="flex-1">
            <mat-label data-test-id="user-form-linkedin-label">LinkedIn URL</mat-label>
            <input type="text" matInput placeholder="LinkedIn URL" formControlName="linkedInUrl"
              data-test-id="user-form-linkedin">
          </mat-form-field>
        </div>
      </ng-container>

      <ng-container *ngIf="showClientExtraFields">
        <div class="flex flex-row gap-4">
          <mat-form-field class="flex-1">
            <mat-label data-test-id="user-form-role-label">User role</mat-label>
            <mat-select placeholder="User role" formControlName="userRole" data-test-id="user-form-role">
              <mat-option *ngFor="let userRole of userRoleOpts" [value]="userRole" data-test-id="user-form-role-option">
                {{ userRole | userRole }}
              </mat-option>
            </mat-select>
            <mat-error data-test-id="user-form-role"> Required </mat-error>
          </mat-form-field>
        </div>
      </ng-container>

      <mat-form-field *ngIf="requiresClient" class="flex-1">
        <mat-label data-test-id="user-form-auth-roles-label">Auth roles</mat-label>
        <mat-select placeholder="Auth roles" formControlName="authRoleIds" multiple data-test-id="user-form-auth-roles">
          <mat-option *ngFor="let option of authRoles" [value]="option.id" data-test-id="user-form-auth-roles-option">
            {{option.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="flex justify-end content-stretch items-stretch flex-row gap-2">
      <button mat-flat-button color="primary" type="submit" [disabled]="isLoading" data-test-id="user-form-submit">
        {{buttonText}}
      </button>
    </div>
  </ng-container>

</form>