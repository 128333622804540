<ng-container *ngIf="experts; else emptyMessage">
  <table style="width: 100%" mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="expert">
      <th mat-header-cell *matHeaderCellDef> Expert </th>
      <td mat-cell *matCellDef="let element"> {{element.firstName || ''}} {{element.lastName || ''}} </td>
    </ng-container>

    <ng-container matColumnDef="opportunity">
      <th mat-header-cell *matHeaderCellDef> Opportunity </th>
      <td mat-cell *matCellDef="let element"> {{element.opportunityName}} </td>
    </ng-container>

    <ng-container matColumnDef="call_time">
      <th mat-header-cell *matHeaderCellDef> Requested call time </th>
      <td mat-cell *matCellDef="let element"> {{element.callTime | date:'long'}}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <div class="flex justify-start content-center items-center flex-row gap-5">
          <mat-icon matTooltip="Schedule" (click)="emitNavigateToSchedulerSignal(element)"
            class="ct-icon ct-icon--selectable">
            event_note</mat-icon>
          <span matTooltip="Go to opportunity" (click)="emitNavigateToOpportunitySignal(element)"
            class="ct-icon-button">
            <mat-icon>arrow_forward</mat-icon>
          </span>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">

    </tr>
  </table>
</ng-container>

<ng-template #emptyMessage>
  <h3>No experts are pending scheduling</h3>
</ng-template>